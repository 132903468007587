.wrapper {
  position: relative;
  padding-left: 1.125rem;
  font-family: var(--typography-label-font-family);
}

.wrapper::before {
  position: absolute;
  content: ' ';
  width: 0.75rem;
  height: 0.75rem;
  left: 0;
  top: 50%;
  translate: 0 -50%;
  border-radius: 50%;
}

.success::before {
  background-color: var(--status-color-bg-success);
}

.warning::before {
  background-color: var(--color-state-warning);
}

.error::before {
  background-color: var(--color-alert-status-error);
}

.info::before {
  background-color: var(--color-state-info);
}

.secondary::before {
  background-color: var(--color-state-secondary);
}
