.wrapper {
  max-width: 200px;

  button > svg {
    color: var(--btn-outline-color-text-primary);
  }

  button:hover > svg {
    color: var(--btn-outline-color-text-primary-hover);
  }
}

.status {
  color: var(--color-interactive-action-primary);
}

.progress {
  width: 100%;
  height: 2px;
  border-radius: 6px;
  background-color: var(--greyscale-300);
}

.tumb {
  max-width: 100%;
  height: 2px;
  border-radius: 6px;
  background-color: var(--color-typography-heading);
}

.more_btn {
  width: 100%;
}
