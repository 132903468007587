.modalOverlay {
  z-index: 100;
  background: rgba(0, 0, 0, 50%);
  display: flex;
  justify-content: center;
}

.modalImage {
  pointer-events: all !important;
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.closeButton {
  z-index: 100;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.closeButton:hover {
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .modalOverlay {
    padding-top: 100px;
  }

  .modalWrapper {
    height: 58vh !important;
  }
}
