.skipLink {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  clip: rect(100px, 0, 0, 0);
}

.skipLink:active,
.skipLink:focus {
  padding: 0.25em;
  background-color: var(--color-background-primary);
  color: var(--color-text-primary-blue);
  font-family: var(--typography-label-font-family);
  font-size: var(--typography-button-font-size);
  clip: auto;
  overflow: visible;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  z-index: 600;
  outline: none;
}
