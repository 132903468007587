.button {
  --btn-solid-color-bg-primary: var(--greyscale-100);
  --btn-solid-color-text-primary: var(--color-typography-heading);
  --btn-line-height: var(--typography-body-regular-line-height);
  --btn-font-size: var(--typography-body-small-font-size);
  --btn-font-weight: var(--typography-body-small-bold-font-weight);
}

.selectedTextBorder {
  --btn-solid-color-border-primary: var(--color-typography-heading);
}

.drawerHeader {
  --drawer-header-height: 80px;
}

.cardTitle {
  @media screen and (--desktop) {
    --typography-body-regular-font-size: var(
      --typography-body-large-bold-font-size
    );
    --typography-body-regular-line-height: var(
      --typography-display-large-desktop-font-size
    );
  }
}
