.root {
  display: flex;
  align-items: center;
  position: relative;
  gap: var(--cart-quantity-gap);
  padding: var(--cart-quantity-padding);
  min-height: var(--cart-quantity-min-height);
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  cursor: text;

  &:focus-within {
    border: 1px solid var(--color-interactive-action-primary);
  }

  --cart-quantity-padding: 0 12px;
  --cart-quantity-min-height: 44px;
  --cart-quantity-gap: 6px;
  --control-button-padding: 5px;
  --control-button-size: 26px;
  --input-min-width: 28px;
  --input-height: 44px;
  --input-font-size: 14px;

  &.compact {
    --control-button-size: 20px;
    --cart-quantity-min-height: 30px;
    --cart-quantity-padding: 0 4px;
    --control-button-padding: 3px;
    --input-height: 30px;
    --input-min-width: 20px;
    --input-font-size: 12px;
    --cart-quantity-gap: 0;
  }
}

.controlButton {
  width: var(--control-button-size);

  --icon-stroke-width: 4px;
  --btn-ghost-color-bg-disabled: transparent;
  --btn-ghost-border-width: 0;
  --btn-size-md-padding: var(--control-button-padding);
  --btn-size-md-height: var(--control-button-size);

  svg {
    stroke-width: 4px;

    --btn-icon-size: 100%;
  }

  & > span > svg {
    fill: var(--quantity-selector-color-button);
  }

  &:disabled > span > svg {
    fill: var(--color-quantity-input-button-disabled);
  }
}

.input {
  min-width: var(--input-min-width);
  height: var(--input-height);
  text-align: center;
  border: 0 !important;
  font-size: var(--input-font-size) !important;

  &:focus-visible {
    outline: 1px auto var(--color-text-primary-blue);
    border: none;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  --input-text-color: var(--color-typography-heading);
  --input-text-font-weight: 800;
  --input-padding-default: 0;
  --input-size-md-height: var(--input-height);
}
