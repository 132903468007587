.root {
  padding-bottom: 100px;
}

.brand {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border: 1px solid var(--color-border-primary);
}

.leftSide {
  position: relative;
  max-width: 100%;
  height: 375px;
}

.rightSide {
  width: 100%;
  position: relative;
  background-color: transparent;
}

.gallery {
  height: 375px;
}

.tag {
  font-size: 10px;
  line-height: 14px;
}

.cartPop {
  padding: 16px 16px 14px;
  box-shadow: 0 -16px 20px -8px rgba(12, 37, 76, 10%);
  z-index: 1000;
}

.qty {
  width: min-content;
  min-width: 120px;
}

.finalPrice {
  line-height: 26px;
}

.fullWidth {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
}

.reactTransformWrapper {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 540px) and (max-height: 926px) {
  .zendeskButton {
    bottom: calc(var(--mobile-cart-height) + 0.5rem) !important;
  }

  .zendeskChat {
    bottom: 0 !important;
    max-height: 100vh !important;
    z-index: 9999 !important;
  }
}

@media (min-width: 540px) and (--mobile) {
  .zendeskButton {
    bottom: calc(var(--mobile-cart-height) + 0.5rem) !important;
  }

  .zendeskChat {
    bottom: calc(1rem + 64px + var(--mobile-cart-height)) !important;
    max-height: calc(
      100vh - 2rem - 64px - var(--mobile-cart-height)
    ) !important;
  }
}

@media (min-width: 540px) and (--mobile) and (max-height: 670px) {
  .zendeskChat {
    bottom: calc(1rem + var(--mobile-cart-height)) !important;
    max-height: calc(100vh - 2rem - var(--mobile-cart-height)) !important;
  }
}

@media (--tablet-s) {
  .brand {
    width: 80px;
    height: 80px;
  }

  .leftSide {
    max-width: calc(100% - 468px);
    height: auto;
  }

  .rightSide {
    min-width: 468px;
    width: 468px;
    padding-left: 16px;
  }

  .cartWrap {
    max-width: 355px;
    min-width: 355px;
  }
}

@media (--desktop-m) {
  .leftSide {
    max-width: calc(100% - 432px);
  }

  .rightSide {
    min-width: 432px;
    width: 432px;
    padding-top: 46px;
    padding-bottom: 46px;
  }

  .cartWrap {
    padding: 0 0 0 4px;
  }

  .cardWrap {
    margin-top: 10px;
  }
}

@media (--desktop-xl) {
  .leftSide {
    max-width: calc(100% - 444px);
  }

  .rightSide {
    min-width: 444px;
    width: 444px;
    padding-top: 46px;
    padding-bottom: 46px;
  }

  .cartWrap {
    padding: 0 0 0 4px;
  }
}

.buyNowPayLaterLogo img {
  height: 1em;
}

.hummPayLater :global(.humm-price-info-widget) {
  padding: 0;
  color: var(--color-typography-body) !important;
  font-family: var(--font-family-primary);
}

.paylaterProviderWrapper .hummPayLater :global(.humm-description) {
  font-size: var(--typography-button-font-size);
}

.paylaterProviderWrapper .hummPayLater :global(span.humm-main) {
  display: flex !important;
  flex-wrap: wrap;
  gap: 4px;
}

.paylaterProviderWrapper .hummPayLater :global(span.humm-more-info) {
  display: none;
}

@media screen and (max-width: 1536px) {
  .title {
    font-size: var(--typography-heading-5-font-size);
    line-height: var(--typography-heading-5-line-height);
  }
}

@media screen and (max-width: 1280px) {
  .title {
    font-size: var(--typography-heading-6-font-size);
    line-height: var(--typography-heading-6-line-height);
  }
}

.latitudePayLater {
  /* 1 line of text with 17px + height image + 20px margin (text and image) */
  min-height: calc(
    (var(--typography-body-regular-line-height-extra) * 1) + 37px
  );
}

.latitudePayLater_price_over_threshold {
  /* 2 lines of text with 17px height image + 25px margin (text and image) */
  min-height: calc(
    (var(--typography-body-regular-line-height-extra) * 2) + 42px
  );
}

.gemPayLater {
  /* 24px height image + 5px margin (text and image) */
  min-height: 29px;
}

.gemPayLater_price_over_threshold {
  /* 4 line of text with 24px height image + 10px margin (text and image) */
  min-height: calc(
    (var(--typography-body-regular-line-height-extra) * 4) + 34px
  );
}

.bnplWidgetWrapper {
  min-height: var(--typography-body-regular-line-height-extra);
}

.paylaterProviderWrapper p,
.paylaterProviderWrapper div,
.paylaterProviderWrapper span {
  font-size: var(--typography-body-regular-font-size) !important;
  line-height: var(--typography-body-regular-line-height-extra) !important;
  font-weight: var(--typography-body-regular-font-weight);
}

@media (--mobile) {
  .paylaterProviderWrapper .hummPayLater :global(.humm-description),
  .paylaterProviderWrapper p,
  .paylaterProviderWrapper div,
  .paylaterProviderWrapper span {
    font-size: var(--typography-body-small-font-size) !important;
    line-height: var(--typography-body-small-line-height) !important;
    font-weight: var(--typography-body-small-font-weight);
  }

  .latitudePayLater {
    /* 1 line of text with 17px + height image + 10px margin (text and image) */
    min-height: 37px;
  }

  .latitudePayLater_price_over_threshold {
    /* 2 line of text with 17px height image + 25px margin (text and image) */
    min-height: calc((var(--typography-body-small-line-height) * 2) + 42px);
  }

  .gemPayLater {
    /* 24px height image + 5px margin (text and image) */
    min-height: 29px;
  }

  .gemPayLater_price_over_threshold {
    /* 3 line of text with 24px height image + 5px margin (text and image) */
    min-height: calc((var(--typography-body-small-line-height) * 3) + 34px);
  }

  .bnplWidgetWrapper {
    min-height: var(--typography-body-small-line-height);
  }
}
