.valuesWrapper {
  width: 428px;

  .button {
    --btn-solid-color-bg-primary: var(--greyscale-100);
    --btn-solid-color-text-primary: var(--color-typography-heading);
    --btn-line-height: var(--typography-body-regular-line-height);
    --btn-font-size: var(--typography-body-small-font-size);
    --btn-font-weight: var(--typography-body-small-bold-font-weight);
  }

  .selectedTextBorder,
  .selectedColorBorder {
    --btn-solid-color-border-primary: var(--color-typography-heading);
  }

  .selectedColorBorder {
    --btn-solid-border-width: 3px;
  }

  .colorBorder {
    --btn-solid-color-border-primary: var(--greyscale-300);
  }
}
