.root {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
}

.gapXs {
  gap: 8px;
}

.gapSm {
  gap: 16px;
}

.gapMd {
  gap: 24px;
}

.gapLg {
  gap: 32px;
}
