.alignRight {
  --tag-default-color-bg: var(--greyscale-100, #f5f5f5);
  --tag-padding-vertical: 9px;
  --tag-padding-horizontal: 12px;
  --tag-size-large: 34px;
  --tag-border-radius: 100px;
  --typography-caption-font-weight: 700;
  --tag-default-color-text: var(--color-typography-heading);

  align-items: center;
  flex-direction: row-reverse;
  gap: 4px;
}

.alignLeft {
  --tag-default-color-bg: var(--greyscale-100, #f5f5f5);
  --tag-padding-vertical: 9px;
  --tag-padding-horizontal: 12px;
  --tag-size-large: 34px;
  --tag-border-radius: 100px;
  --typography-caption-font-weight: 700;
  --tag-default-color-text: var(--color-typography-heading);

  align-items: center;
  flex-direction: row;
  gap: 4px;
}
