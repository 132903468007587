.root {
  --typography-body-regular-letter-spacing: 0.15px;
  --typography-body-small-letter-spacing: 0.15px;
  --typography-body-large-letter-spacing: 0.15px;

  border-radius: 12px;
}

.action {
  --typography-body-regular-text-decoration: var(
    --typography-body-regular-underlined-text-decoration
  );
}

.finalPrice {
  font-size: 34px;
}

@media screen and (--mobile) {
  .root {
    --typography-body-large-font-size: var(--typography-body-regular-font-size);
    --typography-body-large-line-height: var(
      --typography-body-regular-line-height
    );

    border-radius: 7px;
  }

  .title {
    max-width: 198px;
  }
}

.title {
  max-width: 452px;
}
