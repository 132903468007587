.inner {
  grid-column-gap: 8px;
  padding: var(--input-padding-default);
}

.inner.focus,
.box > .input.focus {
  border-color: var(--input-border-color-focus);
}

.inner.focus .input::placeholder,
.box > .input.focus::placeholder {
  color: var(--input-placeholder-color-focus);
}

.inner:hover,
.box > .input:hover {
  border-color: var(--input-border-color-focus);
}

.disabled,
.disabled:hover,
.box > .input.disabled:hover {
  border-color: var(--input-border-color-disabled);
}

.box > .input {
  padding: var(--input-padding-default);
}

.input.input {
  outline: none;
}

.input.input:disabled {
  border-color: var(--input-border-color-disabled);
}

.disabledLabel {
  color: var(--input-label-color-disabled);
}

.validationError {
  color: var(--color-state-error);
}
