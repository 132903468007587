.wrapper {
  position: relative;
  border-radius: 6px;
  margin-bottom: 16px;
  overflow: hidden;
}

.controlBtn {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  padding: 30px;
  background-color: var(--color-interactive-action-primary);
}

.playing_layer {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.playing_layer:hover {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
