.box {
  opacity: 1 !important;
  border: 2px solid var(--color-border-line-primary) !important;
}

.checkboxActive {
  border: 0;
}

.checkIcon {
  filter: brightness(100%);
}

.inactiveLabelColor {
  --checkbox-color: var(--color-text-secondary);
}

.activeLabelColor {
  --checkbox-color: var(--color-text-primary);
}

.checked {
  border: 0 !important;
}

.inputField {
  display: none;
}
