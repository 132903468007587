.tooltip {
  height: 200px;
  width: 240px;

  --input-border-color: var(--color-grayscale-white);
}

.drawer,
.tooltip {
  --input-placeholder-font-weight: var(
    --typography-body-regular-bold-font-weight
  );
  --input-size-md-height: 40px;
  --input-placeholder-color: var(--primary-blue-100, #b6bec9);
}

.drawerHeader.drawerHeader {
  --drawer-header-height: 'auto';
  --drawer-header-padding: 0;

  padding-top: 41px;
}

.drawerHeader .close.close {
  --btn-ghost-color-text-secondary: var(--color-typography-heading);

  right: 16px;
  top: 16px;
  transform: none;
  width: 32px;
  height: 32px;
}

.title {
  font-size: var(--typography-body-large-font-size);
  left: var(--typography-body-large-line-height);
  font-weight: var(--typography-body-large-bold-font-weight);
  font-family: var(--typography-body-large-font-family);
  color: var(--color-typography-heading);
}
