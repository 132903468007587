.tag {
  --tag-success-color-bg: var(--product-card-color-bg-tag);
  --tag-size-medium: 23px;
}

@media screen and (--mobile) {
  .brand {
    font-size: 10px;
    line-height: 14px;
  }

  .cardTitle {
    --typography-body-large-font-size: var(--typography-body-regular-font-size);
    --typography-body-large-line-height: var(
      --typography-body-regular-line-height
    );
  }
}

@media screen and (--tablet-m) {
  .featuredCardTitle {
    --typography-body-large-font-size: var(--typography-body-xlarge-font-size);
    --typography-body-large-line-height: var(
      --typography-body-xlarge-line-height
    );
  }
}
