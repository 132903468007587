.root {
  position: relative;
  height: 100%;
}

.root :global(.swiper-slide) {
  background-color: var(--color-background-product-thumb);
}

.slide {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
}

.square {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.pagination {
  position: absolute;
  right: 64px;
  bottom: 32px;
  z-index: 9;
  border: 1px solid var(--color-grayscale-300);
  padding: 9px 16px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  user-select: none;
  color: var(--color-typography-heading);
  background-color: white;
}

.m_pagination {
  height: 48px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  border: 1px solid var(--color-grayscale-300);
  padding: 0 16px;
  border-radius: 100px;
  user-select: none;
  background-color: white;
  gap: 8px;
}

.page_dot {
  width: 24px;
  height: 24px;
  border: 8px solid #fff;
  border-radius: 100%;
  background-color: var(--color-grayscale-300);
}

.page_dot_active {
  background-color: var(--color-interactive-action-primary) !important;
}

.rotateBtn {
  --btn-size-md-height: 48px;
  --btn-icon-size: 32px;
}

@media screen and (--desktop) {
  .slide {
    height: auto;
  }
}

@media screen and (--tablet) {
  .slide {
    min-height: 562px;
  }
}
