.title {
  color: var(--breadcrumbs-color);
}

.link {
  --breadcrumbs-item-color: var(--breadcrumbs-color-icon);
  --breadcrumbs-item-font-family: var(--typography-label-font-family);
  --breadcrumbs-item-letter-spacing: 0.15px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
}
