.sectionWrapper {
  --section-spacing-default: 0;
}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 620px;
}

.button {
  --btn-size-lg-height: 46px;
  --btn-size-lg-padding: 14px 16px;

  border: 1px solid var(--color-typography-heading, #0c254c) !important;
}

.imageContainer {
  border-bottom-left-radius: 100% 80px;
  border-bottom-right-radius: 100% 80px;
}

.container :global(.swiper-horizontal) > :global(.swiper-pagination-bullets) {
  --swiper-pagination-bottom: 28px;
}

.label {
  --typography-body-small-font-weight: var(--typography-large-font-weight);
  --typography-body-small-line-height: var(--typography-label-line-height);
  --typography-body-small-letter-spacing: 1px;
}

.logoContainer {
  right: 16px;
  bottom: 32px;
}

.logoImage {
  position: relative;
  width: 100px;
  height: 40px;
}

.logoText {
  background-color: #1e3b69;
  padding: 6px 12px;

  --typography-body-small-font-weight: var(--typography-large-font-weight);
  --typography-body-small-line-height: var(
    --typography-body-regular-line-height-extra
  );
  --typography-body-small-letter-spacing: 1px;
}

.skuContainer {
  left: 16px;
  bottom: 32px;
  height: fit-content;
}

.sku {
  --typography-body-small-font-size: 10px;
  --typography-body-small-line-height: 12px;
}

.saveUpToButton {
  padding: 6px 12px;
  border-radius: 4px;
  width: fit-content;
  height: 36px;
}

.regularPriceLabel span:last-of-type {
  font-size: 26px;
  font-weight: 800;
}

@media (--desktop) {
  .button {
    min-width: 180px;

    --btn-size-lg-height: 58px;
    --btn-size-lg-padding: 16px;
  }

  .label {
    --typography-body-small-font-size: var(--typography-body-regular-font-size);
    --typography-body-small-line-height: var(
      --typography-body-regular-bold-line-height
    );
  }

  .logoContainer {
    right: 83px;
    bottom: 82px;
  }

  .logoImage {
    width: 160px;
    height: 65px;
  }

  .logoText {
    padding: 12px 16px;

    --typography-body-small-font-size: var(--typography-body-large-font-size);
  }

  .skuContainer {
    flex-direction: column;
    left: auto;
    right: 61px;
    top: 30px;
  }

  .sku {
    --typography-body-small-font-size: 12px;
    --typography-body-small-line-height: 14.4px;
  }

  .saveUpToButton {
    padding: 12px 14px;
    height: 48px;
  }

  .regularPriceLabel span:last-of-type {
    font-size: 36px;
  }
}

.title > div > div > h3 {
  font-size: 56px;
  line-height: 60px;

  @media screen and (--tablet) {
    font-size: 36px;
    line-height: 40px;
  }

  @media screen and (--mobile) {
    font-size: 32px;
    line-height: 36px;
  }
}

.paragraph {
  --typography-body-large-font-size: 36px;
  --typography-body-large-line-height: 40px;

  @media screen and (--tablet) {
    --typography-body-large-font-size: 24px;
    --typography-body-large-line-height: 30px;
  }

  @media screen and (--mobile) {
    --typography-body-large-font-size: 20px;
    --typography-body-large-line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    height: 640px;
  }
}

.invertedColors span {
  color: var(--color-typography-heading);
}
