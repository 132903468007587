.wrapper {
  position: relative;
  width: 100%;
  height: 432px;
  border-radius: 4px;
}

.icon {
  position: relative;
  width: 142px;
  height: 77px;
}

.description {
  p {
    line-height: 16px;
    color: white;
  }
}

.action {
  padding: 16px 44px;
  height: 56px;
  margin-top: auto;

  --btn-solid-color-bg-success: #7a9e19;
}

@media screen and (--desktop) {
  .wrapper {
    height: 100%;
  }

  .icon {
    width: 220px;
    height: 120px;
  }

  .description {
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
