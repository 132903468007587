.filterBtn {
  --btn-solid-color-border-secondary: var(--greyscale-100);
  --btn-solid-color-bg-secondary: var(--greyscale-100);
  --btn-solid-color-text-secondary: var(--color-typography-heading);
  --btn-solid-color-border-secondary-hover: var(--color-typography-heading);
  --btn-solid-color-bg-secondary-hover: var(--greyscale-100);
  --btn-icon-size: 20px;
  --btn-size-md-height: 40px;
}

.selectedBtn {
  --btn-solid-color-border-secondary: var(--color-typography-heading);
  --btn-solid-color-bg-secondary: var(--greyscale-100);
}

.divider {
  border-right: 1px solid var(--product-list-filters-color-separator);
  margin: 3px 0;
  height: 40px;
}

.popInner {
  min-width: 240px;
  max-width: 280px;
  max-height: 480px;
  overflow-y: auto;
}

.option:hover {
  background-color: var(--greyscale-100);
}

.filterLoader {
  width: 188px;
}

.suffix {
  color: var(--filter-item-suffix-color);
}

.stroke2 {
  stroke-width: 2px !important;
}

.searchInputFieldWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.searchInputFieldWrapper > div {
  min-height: 40px;
}
