.cardButton {
  --btn-size-md-height: 24px;
  --btn-icon-size: 24px;
}

.drawerHeader {
  --btn-size-xs-height: 32px;
  --drawer-header-height: 64px;
  --drawer-header-padding: 0 16px;
}

@media screen and (--tablet-s) {
  .drawerHeader {
    --drawer-header-height: 80px;
    --drawer-header-padding: 0 24px;
  }
}

.quantity {
  --typography-body-regular-bold-font-weight: var(
    --typography-subheading-font-weight-bold
  );
}

.drawerFooter {
  --btn-font-weight: var(--typography-body-regular-bold-font-weight);
  --btn-line-height: var(--typography-body-regular-line-height);
  --btn-border-radius-default: 4px;
}
