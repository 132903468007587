.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 54;

  --section-spacing-default: 0;
}

.header * {
  scroll-padding-top: auto;
}

.headerBackground {
  background-color: var(--header-color-bg);
}

.headerBorder {
  border-bottom: 1px solid var(--header-mobile-color-separator);
}

.simpleHeader {
  padding: 24px 16px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  z-index: 1;
  flex: 1;
}

.searchInput {
  --input-stroke-width: 2px;
  --input-padding-horizontal: 8px;

  ::placeholder {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--input-color-placeholder);
  }
}

.iconWrapper {
  z-index: 1;
}

.menuHeader {
  display: none;
  border-bottom: 1px solid var(--header-color-border);
}

.countBadge {
  letter-spacing: -0.5px;

  --color-text-primary: var(--color-text-action-primary);
  --badge-text-padding-rounded: none;
  --badge-number-font-size: 10px;
  --badge-number-line-height: 14px;
  --badge-number-font-weight: 800;
}

@media screen and (--desktop) {
  .header {
    top: 0;
  }

  .simpleHeader {
    padding: 16px 80px;
  }

  .logoWrapper {
    gap: 46px;
  }

  .hamburgerButton {
    display: none;
  }

  .menuHeader {
    padding: 16px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100vw;
    max-height: 60px;
    background-color: white;
  }

  .searchInput {
    max-width: 800px;
  }
}

@media screen and (--desktop-s) {
  .menuHeader {
    padding: 18px 80px;
  }
}

.backdrop {
  position: fixed;
  top: 200px;
  left: 0;
  width: 100%;
  height: calc(100% - 0px);
  background-color: var(--modal-color-mask-bg);
  backdrop-filter: blur(12px);
  opacity: 1;
}

:global(.theme-jc) .jaycarLogoMobile {
  display: block;
}

:global(.theme-rtm) .rtmLogoMobile {
  display: block;
}

@media screen and (--desktop) {
  :global(.theme-jc) .jaycarLogoMobile {
    display: none;
  }

  :global(.theme-rtm) .rtmLogoMobile {
    display: none;
  }

  :global(.theme-jc) .jaycarLogoDesktop {
    display: block;
  }

  :global(.theme-rtm) .rtmLogoDesktop {
    display: block;
  }

  .backdrop {
    top: 112px;
  }
}
