.priceWrapper {
  display: flex;
  gap: 10.7px;
}

.regularPriceLabel {
  --typography-body-regular-font-size: var(
    --typography-body-large-regular-font-size
  );
}

.savePrice {
  --typography-body-regular-font-weight: var(--typography-large-font-weight);
  --typography-body-regular-letter-spacing: 1px;
}

@media screen and (--desktop) {
  .regularPriceLabel {
    --typography-body-regular-font-size: 22px;
  }

  .savePrice {
    --typography-body-regular-font-size: var(--typography-body-large-font-size);
  }
}
