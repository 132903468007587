.searchTitle {
  --typography-heading-4-font-size: var(--typography-body-xlarge-font-size);
  --typography-heading-4-line-height: var(
    --typography-display-large-mobile-line-height
  );
}

.categoryName {
  padding: 10px 24px;
}

.bannerImage {
  width: var(--desktop-banner-width);
  height: var(--desktop-banner-height);
}

@media screen and (--mobile) {
  .bannerImage {
    width: var(--mobile-banner-width);
    height: var(--mobile-banner-height);
  }
}

@media screen and (--tablet-s) {
  .searchTitle {
    --typography-heading-3-font-size: var(
      --typography-display-large-mobile-font-size
    );
    --typography-heading-3-line-height: var(
      --typography-body-xlarge-line-height
    );

    min-width: 358px;
  }
}

@media screen and (--desktop-m) {
  .searchTitle {
    --typography-heading-3-font-size: var(
      --typography-display-x-large-desktop-font-size
    );
    --typography-heading-3-line-height: var(
      --typography-display-x-large-desktop-line-height
    );
  }

  .categoryName {
    padding: 10px 10px 10px 56px;
  }
}
