.richTextWrapper {
  & ol {
    list-style-type: decimal;
  }

  & ol ol,
  & ul ol {
    list-style-type: lower-latin;
  }

  & ol ol ol,
  & ol ul ol,
  & ul ol ol,
  & ul ul ol {
    list-style-type: lower-roman;
  }

  & pre {
    background: var(--greyscale-100, #f5f5f5);
  }

  & .video div {
    margin-bottom: 0;
  }

  --typography-body-xlarge-font-size: 34px;
  --typography-body-xlarge-line-height: 36px;
  --typography-heading-2-font-size: 28px;
  --typography-heading-2-line-height: 32px;
  --typography-heading-3-font-size: 24px;
  --typography-heading-3-line-height: 26px;
  --typography-heading-4-font-size: 20px;
  --typography-heading-4-line-height: 24px;
  --typography-heading-5-font-size: 16px;
  --typography-heading-5-line-height: 18px;
  --typography-heading-6-font-size: 14px;
  --typography-heading-6-line-height: 16px;
}
